@keyframes blink-animation {
  to {
    opacity: 0;
  }
}
@-webkit-keyframes blink-animation {
  to {
    opacity: 0;
  }
}
* {
  box-sizing: border-box;
}

html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding-top: 80px;
  direction: rtl;
  font-family: "Index DL v1 AAA", sans-serif;
  background-color: #fff;
}
@media only screen and (max-width: 1024px) {
  body {
    padding-top: 60px;
  }
}
body.is-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.container,
.container-fluid {
  width: 100%;
}

.container {
  max-width: 1560px;
  padding: 0 60px;
  margin: auto;
}
@media only screen and (max-width: 1024px) {
  .container {
    padding: 0 16px;
  }
}

.container-fluid {
  padding: 0 10px;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

img {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

input, input:hover, input:focus, input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active,
select,
select:hover,
select:focus,
select:active {
  outline: none !important;
  box-shadow: none !important;
}

a:hover, a:focus, a:active,
button:hover,
button:focus,
button:active {
  outline: none !important;
  box-shadow: none !important;
}

input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

#g_a11y_announcement {
  right: 0 !important;
  left: 0 !important;
}

/* Header */
.do-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 10px 16px 10px 0;
  border-bottom: 1px solid #d6e4e5;
  background: #fff;
  z-index: 9999;
}
@media only screen and (max-width: 1024px) {
  .do-header {
    padding: 10px 6px 10px 0;
  }
}
.do-header .container-fluid {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.do-header__logo {
  width: 64px;
  height: 32px;
  display: block;
  margin-left: 62px;
}
@media only screen and (max-width: 1024px) {
  .do-header__logo {
    width: 50px;
    height: 25px;
    margin-left: 0;
  }
}
.do-header__logo svg {
  width: 100%;
  height: 100%;
}
.do-header__search {
  border-radius: 0;
  border: none;
  background: none;
  width: 100%;
  max-width: 560px;
  position: relative;
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  .do-header__search {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
  }
}
.do-header__search-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 21px;
  right: 20px;
  margin: 0;
  z-index: 10;
}
@media only screen and (max-width: 1024px) {
  .do-header__search-icon {
    top: 17px;
    right: 16px;
  }
}
.do-header__search-input {
  border-radius: 0;
  border: none;
  background: none;
  width: 100%;
  padding: 18px 56px 18px 20px;
  border-radius: 10px;
  font-family: "Index DL v1 AAA", sans-serif;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 1px;
  color: #181818;
  background: #edf6f7;
}
@media only screen and (max-width: 1024px) {
  .do-header__search-input {
    padding: 14px 48px 14px 20px;
    border-radius: 0;
    font-size: 16px;
  }
}
.do-header__search-input::-webkit-input-placeholder {
  color: #a8c0c1;
}

.do-header__search-input:-moz-placeholder {
  color: #a8c0c1;
}

.do-header__search-input::-moz-placeholder {
  color: #a8c0c1;
}

.do-header__search-input:-ms-input-placeholder {
  color: #a8c0c1;
}

.do-header__actions {
  margin-right: auto;
}
.do-header__actions-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.do-header__actions-menu li {
  margin: 0 16px;
}
@media only screen and (max-width: 1024px) {
  .do-header__actions-menu li {
    margin: 0 12px;
  }
}
.do-header__actions-menu li:last-of-type {
  margin-left: 0;
}
.do-header__actions-menu button {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  display: block;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s all;
}
.do-header__actions-menu button svg {
  width: 100%;
  height: 100%;
}
.do-header__actions-menu_mobile-search {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .do-header__actions-menu_mobile-search {
    display: block;
    margin: 0 6px !important;
  }
}
.do-header__actions-menu_sprints button, .do-header__actions-menu_tasks button {
  position: relative;
}
.do-header__actions-menu_sprints button small, .do-header__actions-menu_tasks button small {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: -4px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Index DL v1 AAA", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  color: #fff;
  border-radius: 50%;
  background: #dd5353;
}
.do-header__actions-menu_sprints button svg path, .do-header__actions-menu_tasks button svg path {
  transition: 0.3s all;
}
.do-header__actions-menu_sprints button:hover svg path, .do-header__actions-menu_tasks button:hover svg path {
  fill: #9bb5b7 !important;
}
.do-header__actions-menu_new button {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  background: #68b984;
}
@media only screen and (max-width: 1024px) {
  .do-header__actions-menu_new button {
    width: 40px;
    height: 40px;
    font-size: 38px;
    border-radius: 6px;
  }
}
.do-header__actions-menu_new button:hover {
  background: #4ba36a;
}

/* Footer */
.do-footer {
  padding: 14px 16px 14px 0;
  border-top: 1px solid #d6e4e5;
}
@media only screen and (max-width: 1024px) {
  .do-footer {
    padding: 12px 0;
  }
}
.do-footer .container-fluid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.do-footer__copyrights {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #b9cbcc;
}
.do-footer__logo {
  width: 50px;
  height: 25px;
  display: block;
}
.do-footer__logo svg {
  width: 100%;
  height: 100%;
}

/* Loader */
.do-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: #fff;
}
.do-loader--general {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}
.do-loader--general svg {
  width: 100px;
  height: 50px;
}
@media only screen and (max-width: 1024px) {
  .do-loader--general svg {
    width: 76px;
    height: 32px;
  }
}
.do-loader svg > g > circle {
  animation: blink-animation 0.6s linear infinite;
  -webkit-animation: blink-animation 0.6s linear infinite;
}
.do-loader svg > g > g > g > circle {
  animation: blink-animation 0.6s linear infinite;
  -webkit-animation: blink-animation 0.6s linear infinite;
  animation-delay: 0.25s;
}

/* Timeline */
/* Tasks list with date heading */
.do-tasks__list-day_heading {
  padding: 15px 0;
  background: #f5fbfc;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_heading {
    padding: 12px 0;
  }
}
.do-tasks__list-day_heading h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #a8c0c1;
}
.do-tasks__list-day_items {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_items {
    overflow-x: hidden;
  }
}
.do-tasks__list-day_item {
  width: 100%;
  border-bottom: 1px solid #edf6f7;
}
@media only screen and (min-width: 1025px) {
  .do-tasks__list-day_item {
    background: #fff;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item {
    position: relative;
    right: 0;
    transition: 0.3s all;
  }
}
.do-tasks__list-day_item .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item .container {
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    border-right: 6px solid;
    background: #fff;
    z-index: 2;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item.transition-disabled {
    transition: none;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item.priority--low .container {
    border-right-color: #ffbf00;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item.priority--medium .container {
    border-right-color: #ef9a53;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item.priority--high .container {
    border-right-color: #dd5353;
  }
}
@media only screen and (min-width: 1025px) {
  .do-tasks__list-day_item.do-dragged {
    border: 1px dashed #d6e4e5;
    background: #edf6f7;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item.do-dragged .container {
    border: 1px dashed #d6e4e5;
    background: #edf6f7;
  }
}
.do-tasks__list-day_item.do-dragged .container > * {
  opacity: 0;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item.sortable-fallback .do-tasks__list-day_item__mobile-delete, .do-tasks__list-day_item.sortable-fallback .do-tasks__list-day_item__mobile-calendar, .do-tasks__list-day_item.do-dragged .do-tasks__list-day_item__mobile-delete, .do-tasks__list-day_item.do-dragged .do-tasks__list-day_item__mobile-calendar {
    display: none;
  }
}
.do-tasks__list-day_item__mark {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  border-radius: 50%;
  border: 1px solid #b9cbcc;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__mark {
    width: 28px;
    height: 28px;
    margin: auto 0 auto 16px;
  }
}
.do-tasks__list-day_item__mark svg {
  display: none;
}
.do-tasks__list-day_item__mark svg path {
  fill: #b9cbcc;
}
@media only screen and (min-width: 1025px) {
  .do-tasks__list-day_item__mark:hover svg {
    display: block;
  }
}
.do-tasks__list-day_item__label {
  width: 100%;
  max-width: calc(53% - 12px - 20px - 34px - 20px);
  padding-left: 20px;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 1px;
  color: #95b3b4;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__label {
    max-width: calc(100% - 28px - 16px);
    padding-left: 0;
    margin: auto 0;
    font-size: 18px;
    line-height: 25px;
  }
}
.do-tasks__list-day_item small {
  width: 100%;
  padding-left: 20px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #181818;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item small {
    width: auto;
    padding-left: 8px;
    margin-top: 10px;
    font-size: 14px;
  }
}
.do-tasks__list-day_item__date {
  max-width: 12%;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__date {
    max-width: 50%;
    padding-left: 0;
    order: 999;
  }
}
.do-tasks__list-day_item__sprint, .do-tasks__list-day_item__priority {
  font-weight: 500;
}
.do-tasks__list-day_item__sprint {
  max-width: 16%;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__sprint {
    max-width: 50%;
  }
}
.do-tasks__list-day_item__priority {
  max-width: 4%;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__priority {
    display: none;
  }
}
.do-tasks__list-day_item__calendar, .do-tasks__list-day_item__remove {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  cursor: pointer;
  transition: 0.2s opacity, 0.2s filter;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__calendar, .do-tasks__list-day_item__remove {
    display: none;
  }
}
.do-tasks__list-day_item__calendar svg, .do-tasks__list-day_item__remove svg {
  transition: 0.2s opacity;
}
.do-tasks__list-day_item__calendar svg path, .do-tasks__list-day_item__remove svg path {
  transition: 0.2s fill;
}
.do-tasks__list-day_item__calendar {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  margin-right: auto;
}
.do-tasks__list-day_item__calendar svg {
  opacity: 0.3;
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.do-tasks__list-day_item__calendar:hover svg {
  opacity: 1;
  filter: none;
  -webkit-filter: grayscale(0);
}
.do-tasks__list-day_item__remove {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  width: 12px;
  margin-right: 40px;
}
.do-tasks__list-day_item__remove:hover svg path {
  fill: #dd5353 !important;
}
@media only screen and (min-width: 1025px) {
  .do-tasks__list-day_item:hover .do-tasks__list-day_item__calendar, .do-tasks__list-day_item:hover .do-tasks__list-day_item__remove {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}
.do-tasks__list-day_item__mobile-delete, .do-tasks__list-day_item__mobile-calendar {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__mobile-delete, .do-tasks__list-day_item__mobile-calendar {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background: none;
    appearance: none;
    -webkit-appearance: none;
    width: 80px;
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    z-index: -1;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__mobile-delete .do-loader, .do-tasks__list-day_item__mobile-calendar .do-loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__mobile-delete .do-loader.is-visible, .do-tasks__list-day_item__mobile-calendar .do-loader.is-visible {
    display: flex !important;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__mobile-delete .do-loader svg, .do-tasks__list-day_item__mobile-calendar .do-loader svg {
    width: 32px;
    height: 32px;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__mobile-delete {
    right: -6px;
    background: #dd5353;
    transition: 0.3s right;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__mobile-delete .do-loader {
    background: #dd5353;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__mobile-calendar {
    left: -6px;
    background: #5f9df7;
    transition: 0.3s left;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__mobile-calendar > svg {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item__mobile-calendar .do-loader {
    background: #5f9df7;
  }
}
.do-tasks__list-day_item.priority--low .do-tasks__list-day_item__priority {
  color: #ffbf00;
}
.do-tasks__list-day_item.priority--medium .do-tasks__list-day_item__priority {
  color: #ef9a53;
}
.do-tasks__list-day_item.priority--high .do-tasks__list-day_item__priority {
  color: #dd5353;
}
.do-tasks__list-day_item.is-late .do-tasks__list-day_item__label, .do-tasks__list-day_item.is-late .do-tasks__list-day_item__date {
  font-weight: 600;
}
@media only screen and (min-width: 1025px) {
  .do-tasks__list-day_item.is-complete .do-tasks__list-day_item__mark:not(:hover) {
    border-color: #e1f1e6;
    background: #e1f1e6;
  }
  .do-tasks__list-day_item.is-complete .do-tasks__list-day_item__mark:not(:hover) svg {
    display: block;
  }
  .do-tasks__list-day_item.is-complete .do-tasks__list-day_item__mark:not(:hover) svg path {
    fill: #fff;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item.is-complete .do-tasks__list-day_item__mark {
    border-color: #e1f1e6;
    background: #e1f1e6;
  }
  .do-tasks__list-day_item.is-complete .do-tasks__list-day_item__mark svg {
    display: block;
  }
  .do-tasks__list-day_item.is-complete .do-tasks__list-day_item__mark svg path {
    fill: #fff;
  }
}
.do-tasks__list-day_item.is-complete .do-tasks__list-day_item__label {
  opacity: 0.3;
}
.do-tasks__list-day_item.is-complete small {
  opacity: 0.2;
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item.remove-open {
    right: 80px;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item.remove-open .do-tasks__list-day_item__mobile-delete {
    right: -80px;
    z-index: 10;
    transition: none;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item.calendar-open {
    right: -80px;
  }
}
@media only screen and (max-width: 1024px) {
  .do-tasks__list-day_item.calendar-open .do-tasks__list-day_item__mobile-calendar {
    left: -80px;
    z-index: 10;
    transition: none;
  }
}
.do-tasks__loader {
  padding: 60px 0;
}

/* Noty notifications */
#noty_layout__top {
  width: 100%;
  max-width: 100%;
  top: 79px;
  left: 0;
  right: 0;
}
@media only screen and (max-width: 1024px) {
  #noty_layout__top {
    top: 60px;
  }
}
#noty_layout__top .noty_bar {
  padding: 14px 0;
  background: #68b984;
}
@media only screen and (max-width: 1024px) {
  #noty_layout__top .noty_bar {
    padding: 12px 0;
  }
}
#noty_layout__top .noty_bar.noty_type__warning {
  background: #dd5353;
}
#noty_layout__top .noty_bar.noty_type__warning .noty_body {
  margin: 0 auto 8px;
}
#noty_layout__top .noty_bar.noty_type__warning .noty_buttons .btn.btn-success {
  color: #fff;
  background-color: #c74a4a;
}
#noty_layout__top .noty_bar.noty_type__warning .noty_buttons .btn.btn-error {
  color: #c74a4a;
  background-color: #fff;
}
#noty_layout__top .noty_bar span {
  font-size: 16px;
  letter-spacing: 1px;
  color: #fff;
}
@media only screen and (max-width: 1024px) {
  #noty_layout__top .noty_bar span {
    font-size: 15px;
    letter-spacing: 0.8px;
  }
}
#noty_layout__top .noty_bar.noty_has_timeout.noty_has_progressbar .noty_progressbar {
  height: 6px;
}
@media only screen and (max-width: 1024px) {
  #noty_layout__top .noty_bar.noty_has_timeout.noty_has_progressbar .noty_progressbar {
    height: 4px;
  }
}
#noty_layout__top .noty_bar .noty_body,
#noty_layout__top .noty_bar .noty_buttons {
  max-width: 1560px;
  padding: 0 60px;
  margin: auto;
}
@media only screen and (max-width: 1024px) {
  #noty_layout__top .noty_bar .noty_body,
  #noty_layout__top .noty_bar .noty_buttons {
    padding: 0 16px;
  }
}
#noty_layout__top .noty_bar .noty_body .btn,
#noty_layout__top .noty_bar .noty_buttons .btn {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  margin: 0 5px;
  padding: 6px 10px;
  border-radius: 6px;
  font-family: "Index DL v1 AAA", sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  #noty_layout__top .noty_bar .noty_body .btn,
  #noty_layout__top .noty_bar .noty_buttons .btn {
    font-size: 15px;
    letter-spacing: 0.8px;
  }
}
#noty_layout__top .noty_bar .noty_body .btn:first-of-type,
#noty_layout__top .noty_bar .noty_buttons .btn:first-of-type {
  margin-right: 0;
}
#noty_layout__top .noty_bar .noty_body .btn:last-of-type,
#noty_layout__top .noty_bar .noty_buttons .btn:last-of-type {
  margin-left: 0;
}