// Fonts
$main-font: 'Index DL v1 AAA', sans-serif;

// Colors
$white: #fff;
$black: #000;

$brand-dark-1: #181818;

$brand-light-gray-1: #edf6f7;
$brand-light-gray-2: #d6e4e5;
$brand-light-gray-3: #b5c2c3;
$brand-light-gray-4: #b9cbcc;
$brand-light-gray-5: #a8c0c1;
$brand-light-gray-6: #f5fbfc;
$brand-light-gray-7: #95b3b4;
$brand-light-gray-8: #e9f1f2;

$brand-purple-1: #7743db;
$brand-purple-2: #5837d0;

$brand-pink-1: #ff8dc7;

$brand-green-1: #68b984;

$brand-orange-1: #ef9a53;

$brand-yellow-1: #ffbf00;

$brand-light-blue-1: #5f9df7;

$brand-red-1: #dd5353;
$brand-red-2: #c74a4a;

$primary: $brand-green-1;

// Keyframe animations
@keyframes blink-animation {

    to {
        opacity: 0;
    }
}

@-webkit-keyframes blink-animation {

    to {
        opacity: 0;
    }
}