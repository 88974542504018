* {
    box-sizing: border-box;
}

html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

body {
    margin: 0;
    padding-top: 80px;

    direction: rtl;
    font-family: $main-font;

    background-color: $white;

    @include sm {
        padding-top: 60px;
    }

    &.is-loading {
        position: fixed;
        top: 0; right: 0; bottom: 0; left: 0;
    }
}

.container,
.container-fluid {
    width: 100%;
}

.container {
    max-width: 1560px;

    padding: 0 60px;
    margin: auto;

    @include sm {
        padding: 0 16px;
    }
}

.container-fluid {
    padding: 0 10px;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

img {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

input,
textarea,
select {

    &,
    &:hover,
    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

a,
button {

    &:hover,
    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
}

input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none; 
}

// Google auth strange pixel overflow fix
#g_a11y_announcement {
    right: 0 !important;
    left: 0 !important;
}