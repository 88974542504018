/* Header */
.do-header {
    position: fixed;
    top: 0; right: 0; left: 0;

    padding: 10px 16px 10px 0;

    border-bottom: 1px solid $brand-light-gray-2;
    background: $white;

    z-index: 9999;

    @include sm {
        padding: 10px 6px 10px 0;
    }

    .container-fluid {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__logo {
        width: 64px;
        height: 32px;

        display: block;
        margin-left: 62px;

        @include sm {
            width: 50px;
            height: 25px;
            margin-left: 0;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__search {
        @include input-reset();

        width: 100%;
        max-width: 560px;

        position: relative;
        padding: 0;
        margin: 0;

        @include sm {
            display: none;

            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
        }

        &-icon {
            width: 16px;
            height: 16px;
            
            position: absolute;
            top: 21px;
            right: 20px;

            margin: 0;
            z-index: 10;

            @include sm {
                top: 17px;
                right: 16px;
            }
        }

        &-input {
            @include input-reset();

            width: 100%;
            padding: 18px 56px 18px 20px;
            border-radius: 10px;

            font-family: $main-font;
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 1px;
            color: $brand-dark-1;

            background: $brand-light-gray-1;

            @include sm {
                padding: 14px 48px 14px 20px;
                border-radius: 0;
                font-size: 16px;
            }

            @include placeholder() {
                color: $brand-light-gray-5;
            }
        }
    }

    &__actions {
        margin-right: auto;

        &-menu {
            display: flex;
            flex-direction: row;
            align-items: center;

            li {
                margin: 0 16px;

                @include sm {
                    margin: 0 12px;
                }

                &:last-of-type {
                    margin-left: 0;
                }
            }

            button {
                @include btn-reset();

                display: block;
                line-height: 1;

                cursor: pointer;
                transition: 0.3s all;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            &_mobile-search {
                display: none;

                @include sm {
                    display: block;
                    margin: 0 6px !important;
                }
            }

            &_sprints,
            &_tasks {

                button {
                    position: relative;

                    small {
                        width: 16px;
                        height: 16px;

                        position: absolute;
                        bottom: -4px;
                        right: -8px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        font-family: $main-font;
                        font-size: 10px;
                        font-weight: 600;
                        line-height: 13px;
                        color: $white;

                        border-radius: 50%;
                        background: $brand-red-1;
                    }

                    svg {

                        path {
                            transition: 0.3s all;
                        }
                    }

                    &:hover {

                        svg {
                            
                            path {
                                fill: darken($brand-light-gray-4, 10) !important;
                            }
                        }
                    }
                }
            }

            &_new {
                
                button {
                    width: 58px;
                    height: 58px;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    font-size: 48px;
                    font-weight: 500;
                    color: $white;

                    border-radius: 10px;
                    background: $primary;

                    @include sm {
                        width: 40px;
                        height: 40px;

                        font-size: 38px;
                        border-radius: 6px;
                    }

                    &:hover {
                        background: darken($primary, 10);
                    }    
                }
            }
        }
    }
}

/* Footer */
.do-footer {
    padding: 14px 16px 14px 0;
    border-top: 1px solid $brand-light-gray-2;

    @include sm {
        padding: 12px 0;
    }

    .container-fluid {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__copyrights {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.3px;
        color: $brand-light-gray-4;
    }

    &__logo {
        width: 50px;
        height: 25px;

        display: block;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

/* Loader */
.do-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    background-color: $white;

    &--general {
        position: fixed;
        top: 0; right: 0; bottom: 0; left: 0;
        z-index: 9999;

        svg {
            width: 100px;
            height: 50px;

            @include sm {
                width: 76px;
                height: 32px;
            }
        }
    }

    svg {

        > g {

            > circle {
                animation: blink-animation 0.6s linear infinite;
                -webkit-animation: blink-animation 0.6s linear infinite;
            }

            > g {

                > g {

                    > circle {
                        animation: blink-animation 0.6s linear infinite;
                        -webkit-animation: blink-animation 0.6s linear infinite;
                        animation-delay: 0.25s;        
                    }
                }
            }
        }
    }
}

/* Timeline */


/* Tasks list with date heading */
.do-tasks {

    &__list {

        &-day {

            &_heading {
                padding: 15px 0;
                background: $brand-light-gray-6;

                @include sm {
                    padding: 12px 0;
                }
    
                h2 {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    color: $brand-light-gray-5;
                }
            }

            &_items {
                width: 100%;

                @include sm {
                    overflow-x: hidden;
                }
            }

            &_item {
                width: 100%;
                border-bottom: 1px solid $brand-light-gray-1;

                @include sm-up {
                    background: $white;
                }

                @include sm {
                    position: relative;
                    right: 0;

                    transition: 0.3s all;
                }

                .container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    padding-top: 12px;
                    padding-bottom: 12px;
    
                    @include sm {
                        position: relative;

                        align-items: flex-start;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        border-right: 6px solid;
                        background: $white;

                        z-index: 2;
                    }
                }

                &.transition-disabled {

                    @include sm {
                        transition: none;
                    }
                }

                &.priority {

                    &--low {

                        .container {

                            @include sm {
                                border-right-color: $brand-yellow-1;
                            }    
                        }
                    }

                    &--medium {

                        .container {

                            @include sm {
                                border-right-color: $brand-orange-1;
                            }    
                        }
                    }

                    &--high {

                        .container {

                            @include sm {
                                border-right-color: $brand-red-1;
                            }    
                        }
                    }
                }

                &.do-dragged {

                    @include sm-up {
                        border: 1px dashed $brand-light-gray-2;
                        background: $brand-light-gray-1;
                    }

                    .container {

                        @include sm {
                            border: 1px dashed $brand-light-gray-2;
                            background: $brand-light-gray-1;    
                        }
        
                        > * {
                            opacity: 0;
                        }
                    }
                }

                &.sortable-fallback,
                &.do-dragged {

                    .do-tasks__list-day_item {

                        &__mobile {

                            &-delete,
                            &-calendar {
                                
                                @include sm {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                &__mark {
                    @include btn-reset();

                    width: 34px;
                    height: 34px;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 20px;
                    
                    border-radius: 50%;
                    border: 1px solid $brand-light-gray-4;

                    cursor: pointer;

                    @include sm {
                        width: 28px;
                        height: 28px;

                        margin: auto 0 auto 16px;
                    }

                    svg {
                        display: none;

                        path {
                            fill: $brand-light-gray-4;
                        }
                    }

                    &:hover {

                        svg {

                            @include sm-up {
                                display: block;
                            }        
                        }
                    }
                }

                &__label {
                    width: 100%;
                    max-width: calc(85% - 12% - 16% - 4% - 12px - 20px - 34px - 20px);
                    padding-left: 20px;

                    font-size: 22px;
                    line-height: 29px;
                    letter-spacing: 1px;
                    color: $brand-light-gray-7;

                    @include sm {
                        max-width: calc(100% - 28px - 16px);
                        padding-left: 0;
                        margin: auto 0;

                        font-size: 18px;
                        line-height: 25px;
                    }
                }

                small {
                    width: 100%;
                    padding-left: 20px;

                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                    color: $brand-dark-1;

                    @include sm {
                        width: auto;
                        padding-left: 8px;
                        margin-top: 10px;
                        
                        font-size: 14px;
                    }
                }

                &__date {
                    max-width: 12%;

                    @include sm {
                        max-width: 50%;
                        padding-left: 0;
                        order: 999;
                    }
                }

                &__sprint,
                &__priority {
                    font-weight: 500;
                }

                &__sprint {
                    max-width: 16%;

                    @include sm {
                        max-width: 50%;
                    }
                }

                &__priority {
                    max-width: 4%;

                    @include sm {
                        display: none;
                    }
                }

                &__calendar,
                &__remove {
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;

                    cursor: pointer;
                    transition: 0.2s opacity, 0.2s filter;

                    @include sm {
                        display: none
                    }

                    svg {
                        transition: 0.2s opacity;

                        path {
                            transition: 0.2s fill;
                        }
                    }
                }

                &__calendar {
                    @include btn-reset();

                    width: 20px;
                    margin-right: auto;

                    svg {
                        opacity: 0.3;

                        filter: gray;
                        -webkit-filter: grayscale(1);
                        filter: grayscale(1);
                    }

                    &:hover {

                        svg {
                            opacity: 1;

                            filter: none;
                            -webkit-filter: grayscale(0);    
                        }
                    }
                }

                &__remove {
                    @include btn-reset();

                    width: 12px;
                    margin-right: 40px;

                    &:hover {

                        svg {

                            path {
                                fill: $brand-red-1 !important;
                            }
                        }
                    }
                }

                @include sm-up {

                    &:hover {

                        .do-tasks__list-day_item {
    
                            &__calendar,
                            &__remove {
                                opacity: 1;
                                visibility: visible;
                                pointer-events: all;
                            }
                        }
                    }    
                }

                &__mobile-delete,
                &__mobile-calendar {
                    display: none;

                    @include sm {
                        @include btn-reset();

                        width: 80px;

                        position: absolute;
                        top: 0; 
                        bottom: 0; 

                        display: block;
                        z-index: -1;
                    }

                    .do-loader {

                        @include sm {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                        }

                        &.is-visible {

                            @include sm {
                                display: flex !important;
                            }
                        }

                        svg {

                            @include sm {
                                width: 32px;
                                height: 32px;
                            }
                        }
                    }
                }

                &__mobile-delete {

                    @include sm {
                        right: -6px;
                        background: $brand-red-1;
                        transition: 0.3s right;
                    }

                    .do-loader {

                        @include sm {
                            background: $brand-red-1;
                        }
                    }
                }

                &__mobile-calendar {

                    @include sm {
                        left: -6px;
                        background: $brand-light-blue-1;
                        transition: 0.3s left;
                    }

                    > svg {

                        @include sm {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .do-loader {

                        @include sm {
                            background: $brand-light-blue-1;
                        }
                    }
                }

                &.priority {

                    &--low {

                        .do-tasks__list-day_item {

                            &__priority {
                                color: $brand-yellow-1;
                            }
                        }
                    }

                    &--medium {

                        .do-tasks__list-day_item {

                            &__priority {
                                color: $brand-orange-1;
                            }
                        }
                    }

                    &--high {

                        .do-tasks__list-day_item {

                            &__priority {
                                color: $brand-red-1;
                            }
                        }
                    }

                }

                &.is-late {

                    .do-tasks__list-day_item {

                        &__label,
                        &__date {
                            font-weight: 600;
                        }
                    }
                }

                &.is-complete {

                    .do-tasks__list-day_item {

                        &__mark {

                            &:not(:hover) {

                                @include sm-up {
                                    border-color: scale-color($primary, $lightness: 80%);
                                    background: scale-color($primary, $lightness: 80%);
        
                                    svg {
                                        display: block;
        
                                        path {
                                            fill: $white;
                                        }
                                    }    
                                }
                            }

                            @include sm {
                                border-color: scale-color($primary, $lightness: 80%);
                                background: scale-color($primary, $lightness: 80%);
    
                                svg {
                                    display: block;
    
                                    path {
                                        fill: $white;
                                    }
                                }
                            }
                        }

                        &__label {
                            opacity: 0.3;
                        }
                    }

                    small {
                        opacity: 0.2;
                    }
                }

                &.remove-open {
                    
                    @include sm {
                        right: 80px;
                    }

                    .do-tasks__list-day_item {

                        &__mobile-delete {

                            @include sm {
                                right: -80px;
                                z-index: 10;
                                transition: none;
                            }
                        }    
                    }
                }

                &.calendar-open {

                    @include sm {
                        right: -80px;
                    }

                    .do-tasks__list-day_item {

                        &__mobile-calendar {

                            @include sm {
                                left: -80px;
                                z-index: 10;
                                transition: none;
                            }
                        }    
                    }
                }
            }
        }
    }

    &__loader {
        padding: 60px 0;
    }
}

/* Noty notifications */
#noty_layout__top {
    width: 100%;
    max-width: 100%;

    top: 79px;
    left: 0;
    right: 0;

    @include sm {
        top: 60px;
    }

    .noty_bar {
        padding: 14px 0;
        background: $primary;

        @include sm {
            padding: 12px 0;
        }

        &.noty_type__warning {
            background: $brand-red-1;

            .noty_body {
                margin: 0 auto 8px;
            }

            .noty_buttons {

                .btn {

                    &.btn-success {
                        color: $white;
                        background-color: $brand-red-2;
                    }

                    &.btn-error {
                        color: $brand-red-2;
                        background-color: $white;
                    }
                }
            }
        }

        span {
            font-size: 16px;
            letter-spacing: 1px;
            color: $white;
            
            @include sm {
                font-size: 15px;
                letter-spacing: 0.8px;
            }
        }

        &.noty_has_timeout {

            &.noty_has_progressbar {
                
                .noty_progressbar {
                    height: 6px;

                    @include sm {
                        height: 4px;
                    }
                }    
            }
        }

        .noty_body,
        .noty_buttons {
            max-width: 1560px;

            padding: 0 60px;
            margin: auto;
        
            @include sm {
                padding: 0 16px;
            }

            .btn {
                @include btn-reset;

                margin: 0 5px;
                padding: 6px 10px;
                border-radius: 6px;

                font-family: $main-font;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 1px;

                cursor: pointer;

                @include sm {
                    font-size: 15px;
                    letter-spacing: 0.8px;    
                }

                &:first-of-type {
                    margin-right: 0;
                }

                &:last-of-type {
                    margin-left: 0;
                }
            }
        }
    }
}