// Mixins
@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

// (1) Placeholders  
@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

// (2) Media Queries
@mixin xl {
    @media only screen and (max-width: 1920px) {
        @content;
    }
}

@mixin lg {
    @media only screen and (max-width: 1680px) {
        @content;
    }
}

@mixin md {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}

@mixin sm {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}

@mixin sm-up {
    @media only screen and (min-width: 1025px) {
        @content;
    }
}

@mixin xs {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin xxs {
    @media only screen and (max-width: 415px) {
        @content;
    }
}

// (3) Misc
// Button styling reset
@mixin btn-reset {
    padding: 0;
    margin: 0;

    border-radius: 0;
    border: none;
    
    background: none;
    appearance: none;
    -webkit-appearance: none;
}

// Input styling reset
@mixin input-reset {
    border-radius: 0;
    border: none;
    background: none;
}